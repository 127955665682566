<template>
	<div class="">

<div class="modal">
			<div class="modalCont">


			<div class="modalHead">

			</div>

			<div class="modalMain">


		<textarea id="tmce" ref="tmce" class="editor" ></textarea>

			</div>

			<div class="modalFooter">
				<div><button @click.stop="closeModal(0)">Abbrechen</button></div>
				<div><button @click.stop="closeModal(1)">Speichern</button></div>
			</div>
		</div>
</div>

	</div>
</template>

<script>

	export default {
		name: 'HtmlEditor',
		components: {
			// Editor,
		},

		beforeMount(){

		},
		mounted(){

			let self = this;
			this.iniTiny().then((resp)=>{

							tinymce.get('tmce').setContent(self.selCont.html_content);
			});



							

		}, // end mounted 
		beforeDestroy(){
			tinymce.remove();
		},

		data: 
		function(){
			return {

			
			}
		}, // end data

		props:[
		'par',
		'selCont',
		],
		methods: {
			closeModal(p){
				let r = tinymce.activeEditor.getContent();
				this.$parent.selContent = r;
				this.par.callback(p);
			},

			iniTiny(){

				return new Promise((resolve, reject)=>{





					let self = this;



					tinymce.init({

						selector: 'textarea#tmce',
						theme: 'modern',
						width: '100%',
						height: 400,
				// contextmenu
				plugins: [
				'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
				'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
				'save table directionality emoticons template paste textcolor hr'
				],
				style_formats: [
        // {title: 'img-float-right', selector: 'img' , inline: '', classes: 'upImgR'},
        // {title: 'img-center', selector: 'img' , inline: '', classes: 'upImgC'},
        // {title: 'img-border-gray', selector: 'img' , inline: '', classes: 'upImgBorderGray'},
        {title: 'Überschrift 1', inline: 'span', 'classes': 'upHeadline1'},
        {title: 'Überschrift 2', inline: 'span', 'classes': 'upHeadline2'},        
        {title: 'Überschrift 1 schwarz', inline: 'span', 'classes': 'upHeadline1-2'},
        {title: 'Überschrift 2 schwarz', inline: 'span', 'classes': 'upHeadline2-2'},

        ],
        content_css: '/tinymce/css/basic.css',
        toolbar: 'undo redo | bold italic styleselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | hr | fullpage code',
        formats: {
        	alignleft: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'upLeft'},
        	aligncenter: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'upCenter'},
        	alignright: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'upRight'},
        	alignjustify: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'upFull'},
        	bold: {inline: 'span', 'classes': 'upBold'},
        	italic: {inline: 'span', 'classes': 'upItalic'},
        	underline: {inline: 'span', 'classes': 'underline', exact: true},
        	strikethrough: {inline: 'del'},
        	forecolor: {inline: 'span', classes: 'forecolor', styles: {color: '%value'}},
        	hilitecolor: {inline: 'span', classes: 'hilitecolor', styles: {backgroundColor: '%value'}},
        	custom_format: {block: 'h1', attributes: {title: 'Header'}, styles: {color: 'red'}},


        },
        init_instance_callback: function (editor) {
					resolve('iniTiny ready');
        	editor.on('click', function (e) {
						// console.log('Element clicked:', e.target.nodeName);
						// self.saveContent();
					});					
        	editor.on('MouseLeave', function (e) {
						// self.saveContent();

					});

        }
      });

				});


			},

		} // end methods
	} // end export default

</script>



<style scoped lang="scss">



.modal {
	position: absolute;
	top: 0;
	left: 0rem;
	background-color: rgb(0,0,0, .5);
	width: 100vw;
	height: 100vh;
	// z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;

		& > .modalCont {
			background-color: white;

			padding: 2rem;

			border-radius: 10px;
			box-shadow: 2px 2px 2px #555;

		}

		& .modalMain {
			min-height: 7rem;
			width: 48rem;
			text-align: center;

		}

		& .modalHead {
			text-align: center;
			// background-color: #cea7a7;
			color: white;
			font-weight: bold;
			padding: 0.3rem 1rem;
			margin-bottom: 0.7rem;

		}
	& .modalFooter {
		display: flex;
		justify-content: center;
		align-items: baseline;
		
		& div {
			margin: 1rem;
			min-width: 12rem;

			& button {
				width: 100%;
			}
		}
	}
}
</style>
