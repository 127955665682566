<template>
	<div class="container">

		<div><h2>Home Editor</h2></div>

		

		<htmlEditor v-if="edContainer.visible" :par="edContainer" :selCont="selContent"></htmlEditor>

<div class="innerCont">
			<div v-if="homeDat.length > 0" class="homeList">

				<div class="item" v-for="(v,i) in homeDat">
					<div @click="openEditor(i)">{{ v.node_title }}</div>
					<div class="visCont">
						<div title="sichtbar" v-if="v.visible == 1"><svg class="iVis"  @click="toggleItemVis(i)"><use xlink:href="/sys_img/icons.svg#visible"/></svg></div>
						<div title="nicht sichtbar" v-else ><svg class="iUvis" @click="toggleItemVis(i)"><use xlink:href="/sys_img/icons.svg#unvisible"/></svg></div>
					</div>
					<div title="bearbeiten" @click="openEditor(i)"><svg class="iEdit" @click="" ><use xlink:href="/sys_img/icons.svg#edit"/></svg></div>
				</div>
			</div>

			<div class="hDes"><img :src="$store.state.server + '/img/home_description.png'"></div>
</div>

		</div>



</template>

<script>
	// import  imgMetaEditor from "@/components/ImgMetaEditor.vue";
	import axios from 'axios';
	import  htmlEditor from "@/components/HtmlEditor.vue";
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'HomeEditList',
		components: {
			htmlEditor,

		},
		mounted(){

			this.getHomeList();



		}, // end mounted 

		data: 
		function(){
			return {
				homeDat: [],
				edContainer: {visible: false},
				selContent: '',
				
			}
		}, // end data
		methods: {
			openEditor(key){
				this.edContainer.visible = true;

				this.selContent = this.homeDat[key];
				let self = this;
				self.edContainer.callback = function(p){

					if (p === 1) {
						self.saveHtmlContent(self.homeDat[key].id, self.selContent, self.homeDat[key].slug);
					}
					self.edContainer.visible = false;
				}
			},

			saveHtmlContent(id, cont, slug){
				let self = this;
				let url = this.$store.state.server + '/be/saveHtmlContent.php';



				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { id: id, cont: cont, slug:slug}
				})
				.then(function (resp) {

					// console.log(resp.data.data[0]);
					// console.log(self.homeDat);


					self.homeDat = resp.data.data[0];

				});
			},

			toggleItemVis(key){

				let self = this;
				let url = this.$store.state.server + '/be/toggleImgVis.php';



				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { id: self.homeDat[key].id, vis: self.homeDat[key].visible}
				})
				.then(function (resp) {

					let id = resp.data.data[0];
					let vis = resp.data.data[1];

					self.homeDat[key].visible = vis;
				});

			},

			getHomeList(){

				let self = this;
				let url = this.$store.state.server + '/be/getHomeList.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { slug: 'home' },
				})
				.then(function (resp) {


					self.homeDat = resp.data.data[0];

				});

			},
		

		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.container {
	margin-top: 1rem;
}

.homeList {
	margin: 1rem 0;
	display: flex;
	flex-direction: column;
	width: 22rem;
	
	& .item {
		display: flex;
		align-items: center;
		cursor: pointer; 

		& .visCont {
			width: 4rem;
		}
		& .iEdit {
			fill: #867e7e;
		}

		& svg {
			width: 2rem;
			height: 2rem;
		}

		& div {
			margin-right: 1rem;
		}

		& div:first-child {
			min-width: 12rem;
		}

	}

}

.iVis {
	stroke: #72b579;
}
.iUvis {
	stroke: black;
}

.visCont {
	& svg {
		height: 2rem;
		width: 2rem;
	}
}


.modal {
	position: absolute;
	top: 0;
	left: 0rem;
	background-color: rgb(0,0,0, .5);
	width: 100vw;
	height: 100vh;
	// z-index: 100;
		display: none;
		// display: flex;
		justify-content: center;
		align-items: center;

		& > .modalCont {
			background-color: white;

			padding: 2rem;

			border-radius: 10px;
			box-shadow: 2px 2px 2px #555;

		}

		& .modalMain {
			min-height: 7rem;
			width: 48rem;
			text-align: center;

		}

		& .modalHead {
			text-align: center;
			// background-color: #cea7a7;
			color: white;
			font-weight: bold;
			padding: 0.3rem 1rem;
			margin-bottom: 0.7rem;

		}
	& .modalFooter {
		display: flex;
		justify-content: center;
		align-items: baseline;
		
		& div {
			margin: 1rem;
			min-width: 12rem;

			& button {
				width: 100%;
			}
		}
	}
}

.innerCont {
	display: flex;
	flex-wrap: wrap;
}

.hDes {
	// position: absolute;
	    // top: 8rem;
    // right: 1rem;
 & img {
 	width: 37rem;
 }
}


</style>
